<template>
    <div>
        <showAt breakpoint='large'>
            <desktop />
        </showAt>
        <showAt breakpoint='medium'>
            <tablet />
        </showAt>
        <showAt breakpoint='small'>
            <mobile />
        </showAt>
    </div>
</template>

<script>
    
    
    import desktop from "./desktop"
    import tablet from "./tablet"
    import mobile from "./mobile"

    export default {

        data() {
            return {}
        },
        methods: {
            
        },
        components: {
            desktop,
            tablet,
            mobile
        },
        watch: {

        },
        mounted() {
        }

    }

</script>