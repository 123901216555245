<template>
  <div>
    dashboard main
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },

    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
  },
  components: {},
  watch: {},
  mounted() {},
  created() {},
};
</script>
